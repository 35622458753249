import type {
  FetchAccommodationResponseData,
  FetchAccommodationsPayload,
  FetchAccommodationsResponseData,
} from "@/utils/apis/accommodation";

export enum AccommodationActionTypes {
  FETCH_REQUESTED = "@@accommodation/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@accommodation/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@accommodation/FETCH_FAILED",

  FETCH_ACCOMMODATION_SUCCEEDED_SERVER = "@@accommodation/FETCH_ACCOMMODATION_SUCCEEDED_SERVER",

  // Saga
  FETCH_ACCOMMODATIONS_SAGA = "@@accommodation/FETCH_ACCOMMODATIONS_SAGA",
  FETCH_HOME_ACCOMMODATIONS_SAGA = "@@accommodation/FETCH_HOME_ACCOMMODATIONS_SAGA",
}

// State

export interface AccommodationState {
  hydrated?: boolean;

  accommodations: FetchAccommodationsResponseData["Accommodation"];
  accommodationsLoading: boolean;
  accommodationsError: string;

  accommodation: FetchAccommodationResponseData | null;
  accommodationLoading: boolean;
  accommodationError: string;

  homeAccommodations: FetchAccommodationsResponseData["Accommodation"];
  homeAccommodationsLoading: boolean;
  homeAccommodationsError: string;
}

// ---- Reducer Action ----

export type FetchScope =
  | "homeAccommodations"
  | "accommodations"
  | "accommodation";

export type FetchRequestedAction = {
  type: AccommodationActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: AccommodationActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: AccommodationState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: AccommodationActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchAccommodationSucceededServerAction = {
  type: AccommodationActionTypes.FETCH_ACCOMMODATION_SUCCEEDED_SERVER;
  payload: FetchAccommodationResponseData | null;
};

// ---- Saga Action ----

export type FetchAccommodationsSagaAction = {
  type: AccommodationActionTypes.FETCH_ACCOMMODATIONS_SAGA;
  payload?: FetchAccommodationsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchHomeAccommodationsSagaAction = {
  type: AccommodationActionTypes.FETCH_HOME_ACCOMMODATIONS_SAGA;
  payload?: Omit<FetchAccommodationsPayload, "params">;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type AccommodationAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchAccommodationSucceededServerAction
  //
  | FetchAccommodationsSagaAction
  | FetchHomeAccommodationsSagaAction;
