import type {
  FetchAccommodationTagsPayload,
  FetchAccommodationTagsResponseData,
} from "@/utils/apis/accommodationTag";

export enum AccommodationTagActionTypes {
  FETCH_REQUESTED = "@@accommodationTag/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@accommodationTag/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@accommodationTag/FETCH_FAILED",

  // Saga
  FETCH_ACCOMMODATION_TAGS_SAGA = "@@accommodationTag/FETCH_ACCOMMODATION_TAGS_SAGA",
}

// State

export interface AccommodationTagState {
  accommodationTags: FetchAccommodationTagsResponseData;
  accommodationTagsLoading: boolean;
  accommodationTagsError: string;
}

// ---- Reducer Action ----

export type FetchScope = "accommodationTags";

export type FetchRequestedAction = {
  type: AccommodationTagActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: AccommodationTagActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: AccommodationTagState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: AccommodationTagActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga Action ----

export type FetchAccommodationTagsSagaAction = {
  type: AccommodationTagActionTypes.FETCH_ACCOMMODATION_TAGS_SAGA;
  payload?: FetchAccommodationTagsPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type AccommodationTagAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchAccommodationTagsSagaAction;
