import { commonAxios } from "@/libs";

import type {
  FetchAccommodationsPayload,
  FetchAccommodationsResponseData,
  FetchAccommodationPayload,
  FetchAccommodationResponseData,
} from "./accommodation.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const accommodationApi = {
  fetchAccommodations: (payload?: FetchAccommodationsPayload) => {
    return commonAxios.post<AxiosResponseData<FetchAccommodationsResponseData>>(
      "accommodation/accommodations/list.json",
      payload?.params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchAccommodation: (payload: FetchAccommodationPayload) => {
    return commonAxios.post<AxiosResponseData<FetchAccommodationResponseData>>(
      "accommodation/accommodations/detail.json",
      payload.params,
      {
        headers: payload?.headers,
        cancelToken: payload?.cancelToken,
      }
    );
  },
};

export default accommodationApi;
