import { commonAxios } from "@/libs";

import type {
  FetchAccommodationTagsPayload,
  FetchAccommodationTagsResponseData,
} from "./accommodationTag.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const accommodationAccommodationTagApi = {
  fetchAccommodationTags: (payload: FetchAccommodationTagsPayload) => {
    return commonAxios.post<
      AxiosResponseData<FetchAccommodationTagsResponseData>
    >("setting/tags/accommodation_list.json", undefined, {
      cancelToken: payload?.cancelToken,
    });
  },
};

export default accommodationAccommodationTagApi;
