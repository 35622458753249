import type {
  FetchAccommodationCategoriesPayload,
  FetchAccommodationCategoriesResponseData,
} from "@/utils/apis/accommodationCategory";

export enum AccommodationCategoryActionTypes {
  FETCH_REQUESTED = "@@accommodationCategory/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@accommodationCategory/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@accommodationCategory/FETCH_FAILED",

  // Saga
  FETCH_ACCOMMONDATION_CATEGORIES_SAGA = "@@accommodationCategory/FETCH_ACCOMMONDATION_CATEGORIES_SAGA",
}

// State

export interface AccommodationCategoryState {
  accommodationCategories: FetchAccommodationCategoriesResponseData;
  accommodationCategoriesLoading: boolean;
  accommodationCategoriesError: string;
}

// ---- Reducer Action ----

export type FetchScope = "accommodationCategories";

export type FetchRequestedAction = {
  type: AccommodationCategoryActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: AccommodationCategoryActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: AccommodationCategoryState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: AccommodationCategoryActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga Action ----

export type FetchAccommodationCategoriesSagaAction = {
  type: AccommodationCategoryActionTypes.FETCH_ACCOMMONDATION_CATEGORIES_SAGA;
  payload?: FetchAccommodationCategoriesPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type AccommodationCategoryAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchAccommodationCategoriesSagaAction;
