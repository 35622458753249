import type {
  FetchCaptchaCodePayload,
  FetchSocialContactPayload,
  FetchSocialContactResponseData,
  FetchSystemContentsResponseData,
  FetchSystemSettingsPayload,
  FetchSystemSettingsResponseData,
  SubmitContactPayload,
  SubscribeAppPayload,
} from "@/utils/apis/common";

export enum CommonActionTypes {
  FETCH_REQUESTED = "@@common/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@common/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@common/FETCH_FAILED",

  FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_APPLICATION_PROGRESS_SUCCEEDED_SERVER = "@@common/FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_APPLICATION_PROGRESS_SUCCEEDED_SERVER",
  FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_STUDENT_STORIES_SHARING_1_SUCCEEDED_SERVER = "@@common/FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_STUDENT_STORIES_SHARING_1_SUCCEEDED_SERVER",
  FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_STUDENT_STORIES_SHARING_2_SUCCEEDED_SERVER = "@@common/FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_STUDENT_STORIES_SHARING_2_SUCCEEDED_SERVER",
  FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_STUDENT_STORIES_SHARING_3_SUCCEEDED_SERVER = "@@common/FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_STUDENT_STORIES_SHARING_3_SUCCEEDED_SERVER",

  FETCH_SYSTEM_CONTENT_ACCOMMODATION_ACCOMMODATION_IN_JAPAN_SUCCEEDED_SERVER = "@@common/FETCH_SYSTEM_CONTENT_ACCOMMODATION_ACCOMMODATION_IN_JAPAN_SUCCEEDED_SERVER",
  FETCH_SYSTEM_CONTENT_ACCOMMODATION_APPLICATION_PROGRESS_SUCCEEDED_SERVER = "@@common/FETCH_SYSTEM_CONTENT_ACCOMMODATION_APPLICATION_PROGRESS_SUCCEEDED_SERVER",
  FETCH_SYSTEM_CONTENT_ACCOMMODATION_AGENCY_CASE_STUDIES_SUCCEEDED_SERVER = "@@common/FETCH_SYSTEM_CONTENT_ACCOMMODATION_AGENCY_CASE_STUDIES_SUCCEEDED_SERVER",

  // Saga
  FETCH_CAPTCHA_CODE_SAGA = "@@common/FETCH_CAPTCHA_CODE_SAGA",
  FETCH_SOCIAL_CONTACT_SAGA = "@@common/FETCH_SOCIAL_CONTACT_SAGA",
  FETCH_SYSTEM_SETTINGS_SAGA = "@@common/FETCH_SYSTEM_SETTINGS_SAGA",
  SUBSCRIBE_APP_SAGA = "@@common/SUBSCRIBE_APP_SAGA",
  SUBMIT_CONTACT_SAGA = "@@common/SUBMIT_CONTACT_SAGA",
}

// State

export interface CommonState {
  hydrated?: boolean;

  socialContact: FetchSocialContactResponseData | null;
  socialContactLoading: boolean;
  socialContactError: string;

  systemSettings: FetchSystemSettingsResponseData | null;
  systemSettingsLoading: boolean;
  systemSettingsError: string;

  captchaCode: string;
  captchaCodeLoading: boolean;
  captchaCodeError: string;

  systemContentStudyingInJapanApplicationProgress:
    | FetchSystemContentsResponseData[number]
    | null;
  systemContentStudyingInJapanStudentStoriesSharing1:
    | FetchSystemContentsResponseData[number]
    | null;
  systemContentStudyingInJapanStudentStoriesSharing2:
    | FetchSystemContentsResponseData[number]
    | null;
  systemContentStudyingInJapanStudentStoriesSharing3:
    | FetchSystemContentsResponseData[number]
    | null;

  systemContentAccommodationAccommodationInJapan:
    | FetchSystemContentsResponseData[number]
    | null;
  systemContentAccommodationApplicationProgress:
    | FetchSystemContentsResponseData[number]
    | null;
  systemContentAccommodationAgencyCaseStudies:
    | FetchSystemContentsResponseData[number]
    | null;
}

// ---- Reducer Action ----

export type FetchScope = "socialContact" | "systemSettings" | "captchaCode";

export type FetchRequestedAction = {
  type: CommonActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: CommonActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: CommonState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: CommonActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchSystemContentStudyingInJapanApplicationProgressSucceededServerAction =
  {
    type: CommonActionTypes.FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_APPLICATION_PROGRESS_SUCCEEDED_SERVER;
    payload: FetchSystemContentsResponseData[number] | null;
  };

export type FetchSystemContentStudyingInJapanStudentStoriesSharing1SucceededServerAction =
  {
    type: CommonActionTypes.FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_STUDENT_STORIES_SHARING_1_SUCCEEDED_SERVER;
    payload: FetchSystemContentsResponseData[number] | null;
  };

export type FetchSystemContentStudyingInJapanStudentStoriesSharing2SucceededServerAction =
  {
    type: CommonActionTypes.FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_STUDENT_STORIES_SHARING_2_SUCCEEDED_SERVER;
    payload: FetchSystemContentsResponseData[number] | null;
  };

export type FetchSystemContentStudyingInJapanStudentStoriesSharing3SucceededServerAction =
  {
    type: CommonActionTypes.FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_STUDENT_STORIES_SHARING_3_SUCCEEDED_SERVER;
    payload: FetchSystemContentsResponseData[number] | null;
  };

export type FetchSystemContentAccommodationAccommodationInJapanSucceededServerAction =
  {
    type: CommonActionTypes.FETCH_SYSTEM_CONTENT_ACCOMMODATION_ACCOMMODATION_IN_JAPAN_SUCCEEDED_SERVER;
    payload: FetchSystemContentsResponseData[number] | null;
  };

export type FetchSystemContentAccommodationApplicationProgressSucceededServerAction =
  {
    type: CommonActionTypes.FETCH_SYSTEM_CONTENT_ACCOMMODATION_APPLICATION_PROGRESS_SUCCEEDED_SERVER;
    payload: FetchSystemContentsResponseData[number] | null;
  };

export type FetchSystemContentAccommodationAgencyCaseStudiesSucceededServerAction =
  {
    type: CommonActionTypes.FETCH_SYSTEM_CONTENT_ACCOMMODATION_AGENCY_CASE_STUDIES_SUCCEEDED_SERVER;
    payload: FetchSystemContentsResponseData[number] | null;
  };

// ---- Saga Action ----

export type FetchSocialContactSagaAction = {
  type: CommonActionTypes.FETCH_SOCIAL_CONTACT_SAGA;
  payload?: FetchSocialContactPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchSystemSettingsSagaAction = {
  type: CommonActionTypes.FETCH_SYSTEM_SETTINGS_SAGA;
  payload?: FetchSystemSettingsPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type SubscribeAppSagaAction = {
  type: CommonActionTypes.SUBSCRIBE_APP_SAGA;
  payload: SubscribeAppPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type SubmitContactSagaAction = {
  type: CommonActionTypes.SUBMIT_CONTACT_SAGA;
  payload: SubmitContactPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type FetchCaptchaCodeSagaAction = {
  type: CommonActionTypes.FETCH_CAPTCHA_CODE_SAGA;
  payload?: FetchCaptchaCodePayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CommonAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchSystemContentStudyingInJapanApplicationProgressSucceededServerAction
  | FetchSystemContentStudyingInJapanStudentStoriesSharing1SucceededServerAction
  | FetchSystemContentStudyingInJapanStudentStoriesSharing2SucceededServerAction
  | FetchSystemContentStudyingInJapanStudentStoriesSharing3SucceededServerAction
  | FetchSystemContentAccommodationAccommodationInJapanSucceededServerAction
  | FetchSystemContentAccommodationApplicationProgressSucceededServerAction
  | FetchSystemContentAccommodationAgencyCaseStudiesSucceededServerAction
  //
  | FetchSocialContactSagaAction
  | FetchCaptchaCodeSagaAction
  | SubscribeAppSagaAction
  | SubmitContactSagaAction;
