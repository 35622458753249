import { CommonActionTypes } from "./types";
import { HYDRATE } from "next-redux-wrapper";

import { commonHelpers } from "@/utils/helpers";

import type { CommonState, CommonAction } from "./types";

export const initialState: CommonState = {
  hydrated: false,

  socialContact: null,
  socialContactError: "",
  socialContactLoading: true,

  systemSettings: null,
  systemSettingsError: "",
  systemSettingsLoading: true,

  captchaCode: "",
  captchaCodeError: "",
  captchaCodeLoading: true,

  systemContentAccommodationAccommodationInJapan: null,
  systemContentAccommodationAgencyCaseStudies: null,
  systemContentAccommodationApplicationProgress: null,

  systemContentStudyingInJapanApplicationProgress: null,
  systemContentStudyingInJapanStudentStoriesSharing1: null,
  systemContentStudyingInJapanStudentStoriesSharing2: null,
  systemContentStudyingInJapanStudentStoriesSharing3: null,
};

const reducer = (state = initialState, action: CommonAction): CommonState => {
  switch (action.type) {
    case HYDRATE as any: {
      const {
        systemContentAccommodationAccommodationInJapan,
        systemContentAccommodationAgencyCaseStudies,
        systemContentAccommodationApplicationProgress,
        systemContentStudyingInJapanApplicationProgress,
        systemContentStudyingInJapanStudentStoriesSharing1,
        systemContentStudyingInJapanStudentStoriesSharing2,
        systemContentStudyingInJapanStudentStoriesSharing3,
        hydrated,
      } = (action as any).payload.common as CommonState;

      const newState = {
        systemContentAccommodationAccommodationInJapan,
        systemContentAccommodationAgencyCaseStudies,
        systemContentAccommodationApplicationProgress,
        systemContentStudyingInJapanApplicationProgress,
        systemContentStudyingInJapanStudentStoriesSharing1,
        systemContentStudyingInJapanStudentStoriesSharing2,
        systemContentStudyingInJapanStudentStoriesSharing3,
      };

      if (typeof hydrated !== "undefined") {
        Object.entries(newState).forEach(([key, _state]) => {
          if (typeof _state === "undefined") delete (newState as any)[key];
        });
      }

      return {
        ...state,
        ...(typeof hydrated !== "undefined" ? newState : {}),
        hydrated: true,
      };
    }

    case CommonActionTypes.FETCH_REQUESTED: {
      const { scope, isReset } = action.payload;

      const newState = {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: true,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: "",
            }
          : {}),
      };

      if (isReset) {
        Object.assign(newState, {
          [scope]: Array.isArray(newState[scope]) ? [] : null,
        });
      }

      return newState;
    }
    case CommonActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count, isLoadMore } = action.payload;

      let newData = data;
      const stateData = state[scope];

      if (isLoadMore && Array.isArray(stateData) && Array.isArray(data)) {
        const filteredData = data.filter((item) => {
          return stateData.every(
            (stateDataItem) => item.id !== stateDataItem.id
          );
        });
        newData = [...stateData, ...(filteredData as any)] as any;
      }

      return {
        ...state,
        [scope]: newData,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }
    case CommonActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: error,
            }
          : {}),
      };
    }

    case CommonActionTypes.FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_APPLICATION_PROGRESS_SUCCEEDED_SERVER: {
      return {
        systemContentStudyingInJapanApplicationProgress: action.payload,
        hydrated: true,
      } as Partial<CommonState> as CommonState;
    }

    case CommonActionTypes.FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_STUDENT_STORIES_SHARING_1_SUCCEEDED_SERVER: {
      return {
        systemContentStudyingInJapanStudentStoriesSharing1: action.payload,
        hydrated: true,
      } as Partial<CommonState> as CommonState;
    }

    case CommonActionTypes.FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_STUDENT_STORIES_SHARING_2_SUCCEEDED_SERVER: {
      return {
        systemContentStudyingInJapanStudentStoriesSharing2: action.payload,
        hydrated: true,
      } as Partial<CommonState> as CommonState;
    }

    case CommonActionTypes.FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_STUDENT_STORIES_SHARING_3_SUCCEEDED_SERVER: {
      return {
        systemContentStudyingInJapanStudentStoriesSharing3: action.payload,
        hydrated: true,
      } as Partial<CommonState> as CommonState;
    }

    case CommonActionTypes.FETCH_SYSTEM_CONTENT_ACCOMMODATION_ACCOMMODATION_IN_JAPAN_SUCCEEDED_SERVER: {
      return {
        systemContentAccommodationAccommodationInJapan: action.payload,
        hydrated: true,
      } as Partial<CommonState> as CommonState;
    }

    case CommonActionTypes.FETCH_SYSTEM_CONTENT_ACCOMMODATION_APPLICATION_PROGRESS_SUCCEEDED_SERVER: {
      return {
        systemContentAccommodationApplicationProgress: action.payload,
        hydrated: true,
      } as Partial<CommonState> as CommonState;
    }

    case CommonActionTypes.FETCH_SYSTEM_CONTENT_ACCOMMODATION_AGENCY_CASE_STUDIES_SUCCEEDED_SERVER: {
      return {
        systemContentAccommodationAgencyCaseStudies: action.payload,
        hydrated: true,
      } as Partial<CommonState> as CommonState;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
