export { default as authApi } from "./auth";
export { default as commonApi } from "./common";
export { default as serviceApi } from "./service";
export { default as bannerApi } from "./banner";
export { default as schoolApi } from "./school";
export { default as newsItemApi } from "./newsItem";
export { default as cityApi } from "./city";
export { default as tagApi } from "./tag";
export { default as schoolCategoryApi } from "./schoolCategory";
export { default as tipApi } from "./tip";
export { default as tipCategoryApi } from "./tipCategory";
export { default as accommodationApi } from "./accommodation";
export { default as accommodationCategoryApi } from "./accommodationCategory";
export { default as accommodationTagApi } from "./accommodationTag";
