import { commonAxios } from "@/libs";

import type {
  FetchAccommodationCategoriesPayload,
  FetchAccommodationCategoriesResponseData,
} from "./accommodationCategory.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const accommodationCategoryApi = {
  fetchAccommodationCategories: (
    payload: FetchAccommodationCategoriesPayload
  ) => {
    return commonAxios.post<
      AxiosResponseData<FetchAccommodationCategoriesResponseData>
    >("accommodation/accommodation_categories/list.json", undefined, {
      cancelToken: payload?.cancelToken,
    });
  },
};

export default accommodationCategoryApi;
