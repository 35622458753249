import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { accommodationCategoryApi } from "@/utils/apis";

import { AccommodationCategoryActionTypes } from "./types";
import { fetchRequested, fetchFailed, fetchSucceeded } from "./action";

import type {
  FetchAccommodationCategoriesSagaAction,
  FetchScope,
} from "./types";

function* fetchAccommodationCategoriesSaga(
  action: FetchAccommodationCategoriesSagaAction
) {
  const { cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "accommodationCategories" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof accommodationCategoryApi.fetchAccommodationCategories>
    > = yield call(accommodationCategoryApi.fetchAccommodationCategories, {
      cancelToken,
    });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          isLoadMore,
          data: response.params ?? [],
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* accommodationCategorySaga() {
  yield all([
    takeEvery(
      AccommodationCategoryActionTypes.FETCH_ACCOMMONDATION_CATEGORIES_SAGA,
      fetchAccommodationCategoriesSaga
    ),
  ]);
}

export default accommodationCategorySaga;
