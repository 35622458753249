import { CommonActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  FetchSystemContentStudyingInJapanApplicationProgressSucceededServerAction,
  FetchSystemContentStudyingInJapanStudentStoriesSharing1SucceededServerAction,
  FetchSystemContentStudyingInJapanStudentStoriesSharing2SucceededServerAction,
  FetchSystemContentStudyingInJapanStudentStoriesSharing3SucceededServerAction,
  FetchSystemContentAccommodationAccommodationInJapanSucceededServerAction,
  FetchSystemContentAccommodationApplicationProgressSucceededServerAction,
  FetchSystemContentAccommodationAgencyCaseStudiesSucceededServerAction,
  // Saga
  FetchCaptchaCodeSagaAction,
  FetchSocialContactSagaAction,
  FetchSystemSettingsSagaAction,
  SubscribeAppSagaAction,
  SubmitContactSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: CommonActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: CommonActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: CommonActionTypes.FETCH_FAILED,
  payload,
});

export const fetchSystemContentStudyingInJapanApplicationProgressSucceededServer =
  (
    payload: FetchSystemContentStudyingInJapanApplicationProgressSucceededServerAction["payload"]
  ): FetchSystemContentStudyingInJapanApplicationProgressSucceededServerAction => ({
    type: CommonActionTypes.FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_APPLICATION_PROGRESS_SUCCEEDED_SERVER,
    payload,
  });

export const fetchSystemContentStudyingInJapanStudentStoriesSharing1SucceededServer =
  (
    payload: FetchSystemContentStudyingInJapanStudentStoriesSharing1SucceededServerAction["payload"]
  ): FetchSystemContentStudyingInJapanStudentStoriesSharing1SucceededServerAction => ({
    type: CommonActionTypes.FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_STUDENT_STORIES_SHARING_1_SUCCEEDED_SERVER,
    payload,
  });

export const fetchSystemContentStudyingInJapanStudentStoriesSharing2SucceededServer =
  (
    payload: FetchSystemContentStudyingInJapanStudentStoriesSharing2SucceededServerAction["payload"]
  ): FetchSystemContentStudyingInJapanStudentStoriesSharing2SucceededServerAction => ({
    type: CommonActionTypes.FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_STUDENT_STORIES_SHARING_2_SUCCEEDED_SERVER,
    payload,
  });

export const fetchSystemContentStudyingInJapanStudentStoriesSharing3SucceededServer =
  (
    payload: FetchSystemContentStudyingInJapanStudentStoriesSharing3SucceededServerAction["payload"]
  ): FetchSystemContentStudyingInJapanStudentStoriesSharing3SucceededServerAction => ({
    type: CommonActionTypes.FETCH_SYSTEM_CONTENT_STUDYING_IN_JAPAN_STUDENT_STORIES_SHARING_3_SUCCEEDED_SERVER,
    payload,
  });

export const fetchSystemContentAccommodationAccommodationInJapanSucceededServer =
  (
    payload: FetchSystemContentAccommodationAccommodationInJapanSucceededServerAction["payload"]
  ): FetchSystemContentAccommodationAccommodationInJapanSucceededServerAction => ({
    type: CommonActionTypes.FETCH_SYSTEM_CONTENT_ACCOMMODATION_ACCOMMODATION_IN_JAPAN_SUCCEEDED_SERVER,
    payload,
  });

export const fetchSystemContentAccommodationApplicationProgressSucceededServer =
  (
    payload: FetchSystemContentAccommodationApplicationProgressSucceededServerAction["payload"]
  ): FetchSystemContentAccommodationApplicationProgressSucceededServerAction => ({
    type: CommonActionTypes.FETCH_SYSTEM_CONTENT_ACCOMMODATION_APPLICATION_PROGRESS_SUCCEEDED_SERVER,
    payload,
  });

export const fetchSystemContentAccommodationAgencyCaseStudiesSucceededServer = (
  payload: FetchSystemContentAccommodationAgencyCaseStudiesSucceededServerAction["payload"]
): FetchSystemContentAccommodationAgencyCaseStudiesSucceededServerAction => ({
  type: CommonActionTypes.FETCH_SYSTEM_CONTENT_ACCOMMODATION_AGENCY_CASE_STUDIES_SUCCEEDED_SERVER,
  payload,
});

// ---- SAGA ACTION ----

export const fetchCaptchaSaga = (
  payload?: FetchCaptchaCodeSagaAction["payload"],
  meta?: FetchCaptchaCodeSagaAction["meta"]
): FetchCaptchaCodeSagaAction => ({
  type: CommonActionTypes.FETCH_CAPTCHA_CODE_SAGA,
  payload,
  meta,
});

export const fetchSocialContactSaga = (
  payload?: FetchSocialContactSagaAction["payload"],
  meta?: FetchSocialContactSagaAction["meta"]
): FetchSocialContactSagaAction => ({
  type: CommonActionTypes.FETCH_SOCIAL_CONTACT_SAGA,
  payload,
  meta,
});

export const fetchSystemSettingsSaga = (
  payload?: FetchSystemSettingsSagaAction["payload"],
  meta?: FetchSystemSettingsSagaAction["meta"]
): FetchSystemSettingsSagaAction => ({
  type: CommonActionTypes.FETCH_SYSTEM_SETTINGS_SAGA,
  payload,
  meta,
});

export const subscribeAppSaga = (
  payload: SubscribeAppSagaAction["payload"],
  meta?: SubscribeAppSagaAction["meta"]
): SubscribeAppSagaAction => ({
  type: CommonActionTypes.SUBSCRIBE_APP_SAGA,
  payload,
  meta,
});

export const submitContactSaga = (
  payload: SubmitContactSagaAction["payload"],
  meta?: SubmitContactSagaAction["meta"]
): SubmitContactSagaAction => ({
  type: CommonActionTypes.SUBMIT_CONTACT_SAGA,
  payload,
  meta,
});
